import React, { useEffect, useState, useCallback } from 'react'
import { signIn } from '@maphubs/auth'
import { Layout, Row, Form, Input, Button, Card } from 'antd'
import MailOutlined from '@ant-design/icons/MailOutlined'
import OtpInput from 'react-otp-input'

const Login = (): JSX.Element => {
  let callbackUrl = '/'

  useEffect(() => {
    const url = new URL(location.href)
    callbackUrl = url.searchParams.get('callbackUrl')
  })

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const handleSignin = useCallback(async (email) => {
    setLoading(true)
    setEmail(email)
    const res = await signIn('email', {
      email: email,
      redirect: false
    })
    setLoading(false)
    if (res?.error) {
      if (res?.url) {
        window.location.replace(res.url)
      }
    } else {
      setEmailSent(true)
    }
  }, [])

  const [code, setCode] = useState('')

  const onReady = useCallback(
    (otpCode) => {
      window.location.href = `/api/auth/callback/email?email=${encodeURIComponent(
        email
      )}&token=${otpCode}${callbackUrl ? `&callbackUrl=${callbackUrl}` : ''}`
    },
    [callbackUrl, email]
  )

  return (
    <Layout
      title='Login'
      style={{
        height: '100vh',
        width: '100vw'
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: process.env.NEXT_PUBLIC_PRIMARY_COLOR,
          backgroundImage: 'url(/topo3.png)',
          backgroundBlendMode: 'screen'
        }}
      >
        <Row
          align='middle'
          justify='center'
          style={{
            height: '200px',
            width: '100%'
          }}
        >
          <img
            height='100px'
            src='assets/maphubs-logo.png'
            alt='MapHubs Logo'
          />
        </Row>
        {emailSent && (
          <Row
            align='middle'
            justify='center'
            style={{
              width: '100%',
              marginBottom: '50px'
            }}
          >
            <Card title='Enter Code' style={{ border: '2px solid #212121' }}>
              <Row justify='center'>
                <p style={{ fontSize: '16px' }}>
                  We sent a login code to:{' '}
                  <span style={{ fontWeight: '600' }}>{email}</span>
                </p>
              </Row>
              <Row justify='center'>
                <p style={{ fontSize: '16px' }}>Please enter the code below</p>
              </Row>
              <Row
                align='middle'
                justify='center'
                style={{ marginTop: '20px' }}
              >
                <OtpInput
                  value={code}
                  inputStyle={{
                    width: '3rem',
                    height: '4rem',
                    margin: '0 1rem',
                    fontSize: '2.2rem',
                    borderRadius: '4px',
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    color: 'black'
                  }}
                  inputType='tel'
                  onChange={(val) => {
                    setCode(val)
                  }}
                  numInputs={6}
                  renderSeparator=''
                  renderInput={(props) => <input {...props} />}
                />
              </Row>
              <Row justify='end' style={{ marginTop: '50px' }}>
                <Button
                  type='primary'
                  size='large'
                  disabled={code.length !== 6}
                  onClick={() => {
                    onReady(code)
                  }}
                >
                  Submit
                </Button>
              </Row>
            </Card>
          </Row>
        )}
        {!emailSent && (
          <>
            <Row
              align='middle'
              justify='center'
              style={{
                width: '100%',
                marginBottom: '50px'
              }}
            >
              <Card
                title='Customer Login'
                style={{
                  width: '350px',
                  border: '2px solid #212121'
                }}
              >
                <p>
                  If you previously recieved an invite from us, please enter
                  your email here to login again.
                </p>
                <Form
                  name='login'
                  onFinish={(values) => {
                    handleSignin(values.email)
                  }}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onFinishFailed={(errorInfo: any) => {
                    console.log('Failed:', errorInfo)
                  }}
                  validateMessages={{
                    required: '${label} is required!',
                    types: {
                      email: 'Please entered a valid email'
                    }
                  }}
                  initialValues={{
                    remember: true
                  }}
                  style={{
                    width: '300px'
                  }}
                  layout='vertical'
                  size='large'
                >
                  <Form.Item
                    label='Email'
                    name='email'
                    validateTrigger='onBlur'
                    rules={[
                      {
                        required: true
                      },
                      {
                        type: 'email'
                      }
                    ]}
                  >
                    <Input
                      prefix={<MailOutlined />}
                      placeholder='Enter your email'
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type='primary'
                      htmlType='submit'
                      style={{
                        width: '100%'
                      }}
                      loading={loading}
                    >
                      Login
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Row>
          </>
        )}
      </div>
    </Layout>
  )
}

export default Login
